<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-12"
        :label="$t('modulos.classificacao_instrumento.formulario.descricao')"
        obrigatorio
        :max="150"
        trim
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ClassificacaoInstrumentoService from '@common/services/cadastros/ClassificacaoInstrumentoService';
import { ClassificacaoInstrumentoModel } from '@common/models/cadastros/ClassificacaoInstrumentoModel';
import helpers from '@common/utils/helpers';
export default {
  props: ['id', 'paiId'],
  data() {
    return {
      valido: false,
      form: new ClassificacaoInstrumentoModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.classificacao_instrumento.titulos.editar');
      return this.$t('modulos.classificacao_instrumento.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.classificacao_instrumento.validacoes.formulario_invalido');
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'ClassificacaoInstrumento', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'ClassificacaoInstrumento', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ClassificacaoInstrumentoService.buscar(this.id)
        .then((res) => {
          this.form = new ClassificacaoInstrumentoModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.classificacao_instrumento.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
    },
    salvar: function () {
      if(this.paiId) this.form.classificacaoInstrumentoPaiId = this.paiId
      this.$store.dispatch('Layout/iniciarCarregamento');
      ClassificacaoInstrumentoService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'classificacao-instrumento' });
          this.toastSucesso(this.$t(`modulos.classificacao_instrumento.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'classificacao-instrumento' });
      });
    }
  },
};
</script>
